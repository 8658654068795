import { FaStar } from "react-icons/fa";
import { RxDownload } from "react-icons/rx";
import { GiGraduateCap } from "react-icons/gi";
import { TbClockFilled } from "react-icons/tb";
import Link from "next/link";
import { GrCertificate } from "react-icons/gr";
import { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import PopupForm from "@/components/common/PopupForm";

const OurCourseCard = ({
  thumbnail,
  discountedPrice,
  duration,
  title,
  brochureUrl,
  courseredirect,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const handleDownloadClick = () => {
    setShowPopup(true);
  };

  const handleFullNameChange = (evnt) => {
    setFullName(evnt.target.value);
  };
  const handlePhoneChange = (evnt) => {
    setPhoneNumber(evnt.target.value);
  };
  const handleEmailChange = (evnt) => {
    setEmail(evnt.target.value);
  };

  const handleDownloadSubmit = (evnt) => {
    evnt.preventDefault();

    console.log("Downloading brochure");

    setFullName("");
    setPhoneNumber("");
    setEmail("");
    setShowPopup(false);
  };
  return (
    <div className="w-[300px] md:w-[210px] md:h-[380px] lg:h-full md:-ml-6 lg:-ml-0 lg:w-[320px] bg-white shadow-lg shadow-green-200 duration-500 hover:shadow-2xl hover:shadow-colorHeading rounded border-gray-300 rounded-br-[25px] font-Poppins mb-4">
      {/* IMAGE */}
      <div className="w-full">
        <img
          src={thumbnail}
          alt={title}
          width={2170}
          height={1480}
          className="rounded-tl rounded-tr block h-[150px]"
        />
      </div>
      {/* BUTTON  */}
      <div className="-mt-5">
        <button className="bg-yellow ml-4 px-3 py-2 rounded-full uppercase font-medium cursor-default text-sm">
          {discountedPrice > 0 ? "Premium" : "Free"}
        </button>
      </div>

      {/* COURSE NAME */}
      <div className="px-4 py-4">
        <p className="w-full text-[16px] font-medium h-6 mb-5">{title}</p>
      </div>

      {/* FEATURES */}
      <div className="text-sm text-gray-800 font-medium flex flex-col gap-y-2 p-4">
        <div className="flex items-center gap-2">
          <GiGraduateCap className=" text-green-700 text-xl" />
          <p className="text-xs text-zinc-600">40 Seats</p>
        </div>
        <div className="flex items-center gap-2">
          <TbClockFilled className="text-orange-500 text-xl" />
          <p className="text-xs text-zinc-600">{duration}</p>
        </div>
        <div className="flex items-center gap-2">
          <GrCertificate className="text-blue-600 text-xl" />
          <p className="text-xs text-zinc-600">Certificate Included</p>
        </div>
      </div>

      <div className="border-b-[1px] border-gray-300"></div>

      {/* RATING */}
      <div className="m-4 flex">
        <FaStar className=" text-xl text-orange-400" />
        <FaStar className=" text-xl text-orange-400" />
        <FaStar className=" text-xl text-orange-400" />
        <FaStar className=" text-xl text-orange-400" />
        <FaStar className=" text-xl text-gray-400" />
      </div>
      {/* DOWNLOAD THE BROCHURE */}
      <div className="flex justify-between items-center mx-3 pb-1 md:pb-0">
        <Link href={`/courses`} target="_blank">
          <button className="flex items-center px-3 py-2 rounded-md border text-sm w-28 justify-center">
            View Details
          </button>
        </Link>
        <Link href={brochureUrl} target="_blank">
        <button
          className="flex items-center px-3 py-2 rounded-md bg-yellow text-sm w-28 justify-center"
        >
          Syllabus
          <span>
            <RxDownload className="text-green-800 text-lg cursor-pointer ml-1" />
          </span>
        </button>
        </Link>
        {/* <button
          onClick={handleDownloadClick}
          className="flex items-center px-3 py-2 rounded-md bg-yellow text-sm w-28 justify-center"
        >
          Syllabus
          <span>
            <RxDownload className="text-green-800 text-lg cursor-pointer ml-1" />
          </span>
        </button> */}
      </div>
      {showPopup && (
        <div className="fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] p-9 bg-[#e6e5e5] shadow-2xl w-full h-[100vw] backdrop-opacity-10 md:mx-0 lg:w-[50vw] lg:h-[22vw]">
          <div className="">
            <form
              onSubmit={handleDownloadSubmit}
              className="flex justify-center items-center flex-col"
            >
              <h2 className="capitalize text-xl font-semibold mb-5">
                Enter your details to download the program syllabus
              </h2>
              <input
                type="number"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={handlePhoneChange}
                required
                className="h-18 p-4 rounded-sm mb-4 focus:outline-none"
              />
              <input
                type="text"
                placeholder="Email Id"
                value={email}
                onChange={handleEmailChange}
                required
                className="h-18 p-4 rounded-sm focus:outline-none"
              />
              <Link
                href={brochureUrl}
                target="_blank"
                className="flex items-center"
              >
                <button type="submit" className="p-2 mt-6 border border-black">
                  Download Brochure
                </button>
              </Link>
            </form>
            <button
              onClick={() => setShowPopup(false)}
              className="absolute bottom-full right-0"
            >
              <IoCloseCircleOutline className="w-8 h-8" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OurCourseCard;
