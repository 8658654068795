import React, { useState } from 'react';

const PopupForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Phone Number:', phoneNumber);
    console.log('Email:', email);
  };

  return (
    <div>
      <button onClick={() => console.log('Open popup')}>Open Popup</button>
      <div className="popup">
        <form onSubmit={handleSubmit}>
          <label>
            Phone Number:
            <input type="text" value={phoneNumber} onChange={handlePhoneNumberChange} />
          </label>
          <br />
          <label>
            Email:
            <input type="email" value={email} onChange={handleEmailChange} />
          </label>
          <br />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;